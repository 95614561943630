.single-faq-block[data-collapsed=false] .faq-element{
    background-color:var(--grey-bg);
}
.dark-mode .single-faq-block[data-collapsed=false] .faq-element{
    background-color:#222327;
}
.single-faq-block[data-collapsed=false] .single-faq-block-chevron.down{
    display:none;
}
.single-faq-block[data-collapsed=false] .single-faq-block-chevron.up{
    display:block;
}
.single-faq-block[data-collapsed=true] .single-faq-block-chevron.down{
    display:block;
}
.single-faq-block[data-collapsed=true] .single-faq-block-chevron.up{
    display:none;
}
.wmde-markdown, .wmde-markdown *{
    font-size: 14px;
}