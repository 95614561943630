* {
    scrollbar-width: none;
    scrollbar-color: lightgray white;
}

* ::-webkit-scrollbar {
    width: 3px;
    height: 1px;
    position: absolute;
}

* ::-webkit-scrollbar-track {
    background: whitesmoke;
}

* ::-webkit-scrollbar-thumb {
    background: lightgrey;
    border-radius: 10px;
    border: 1px solid darkgray;
}

* ::-webkit-scrollbar-thumb:hover {
    background: lightgrey;
}

.llm-dropdown-grid-list {
    scrollbar-width: thin;
}


.notification-box-container {
    transition: transform 0.2s ease-in-out;
}

.user-menu-dropdown-container, .header-settings-dropdown-container, .header-help-dropdown-container:not(.bottom-side-positionned) {
    display: flex !important;
}

.header-help-dropdown-container.bottom-side-positionned {
    display: flex;
}

.featured-mentors-modals, .prompt-gallery-modal, .mentor-list-modal, .llm-modal, .llm-set-api-key-modal,
.mentor-creation-modal, .add-new-prompt-modal, .edit-mentor-modal, .prompt-gallery-element-view-modal,
.embed-mentor-modal, .dataset-resource-modal, .dataset-url-resource-modal, .users-settings-modal,
.manage-account-modal, .users-settings-invite-modal, .users-settings-invited-users-list-modal, .llm-info-modal,
.help-modal, .faq-modal, .featured-mentor-info-modal, .not-admin-banner-modal {
    display: flex !important;
}

.edit-mentor-llm-tab, .edit-mentor-system-prompt-tab, .edit-mentor-dataset-tab, .edit-mentor-interface-tab,
.edit-mentor-tools-tab {
    display: flex !important;
}

.ai-expert-desc-container {
    max-width: 220px;
}

.stop-responding-btn {
    display: flex !important;
}

.text-inline {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.active-featured-mentor-block {
    display: flex !important;
}


.loader {
    display: flex;
    justify-content: center;
    width: 100%;
}

.loader img {
    width: 20px;
    height: 20px;
}

.page-loader {
    position: fixed;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.llm-prompt-element {
    min-height: 280px;
    min-width: 300px;
}

.page-loader-logo {
    height: 50px;
}

.llm-set-api-key-modal {
    z-index: 2147483641 !important;
}

.Toastify__toast-container--top-center {
    z-index: 2147483647 !important;
}

.prompt-gallery-element-view-desc-form-block, .prompt-gallery-element-view-header-form-block {
    display: block !important;
}

.prompt-gallery-element-save-btn {
    display: flex !important;
}

#users-table {
    width: 100%;
}

#users-table td, #users-table th {
    padding: 8px;
}

.af-view .input-mentor-image-upload {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.upload-action-btn-container {
    background-color: rgba(0, 0, 0, .55) !important;
    display: flex !important;
}

.af-view .user-switcher {
    position: relative;
    display: inline-block;
    width: 33px;
    height: 15px;
    margin-bottom: 0px !important;
}

/* Hide default HTML checkbox */
.af-view .user-switcher input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The user-switcher-slider */
.af-view .user-switcher-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .2s;
    transition: .2s;
}

.af-view .user-switcher-slider:before {
    position: absolute;
    content: \"\";
    height: 20px;
    width: 20px;
    left: -7px;
    bottom: -3px;
    background-color: #656565;
    -webkit-transition: .1s;
    transition: .1s;
}

.af-view .user-switcher input:not(:checked) + .user-switcher-slider img.right-img {
    display: none;
}

.af-view .user-switcher input:checked + .user-switcher-slider img.left-img {
    display: none;
}

.af-view .user-switcher input:checked + .user-switcher-slider:before {
    background-color: #2467eb;
}

.af-view .user-switcher-slider img {
    filter: brightness(0) invert(1) !important;
    width: 16px;
    height: 21px;
    position: absolute;
}

.af-view .user-switcher-slider img.left-img {
    left: 3px;
    bottom: 0px;
}

.af-view .user-switcher-slider img.right-img {
    right: 0px;
    bottom: -1px;
    width: 16px;
}

.af-view .user-switcher input:checked + .user-switcher-slider {
    background-color: rgba(36, 103, 235, 0.6);
}

.af-view .user-switcher input:focus + .user-switcher-slider {
    box-shadow: 0 0 1px rgba(36, 103, 235, 0.6);
}

.af-view .user-switcher input:checked + .user-switcher-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded user-switcher-sliders */
.af-view .user-switcher-slider.round {
    border-radius: 15px;
}

.af-view .user-switcher-slider.round:before {
    border-radius: 50%;
}

.ai-response-rating-thumb.active {
    background-color: lightgray !important;
}

body {
    padding-top: unset !important;
}

.loader-message {
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
}

.page-loader-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.featured-mentors-pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.mentors-pagination {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 10px;
}

.guided-question-block-old {
    width: calc(100% - 30px) !important;
}

body.dark-mode .modal .modal-container {
    border: solid 1px #ffffff75;
}

.menu-container .menu-element-block.current .menu-element-bt-border {
    border-color: #2467eb !important;
}

.ai-response-text-container :is(h1, h2, h3, h4, h5, h6) {
    margin-top: 0px;
    margin-bottom: 0px;
}

.ai-response-text-container pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.ai-response-container-content {
    word-break: break-word;
}

.ai-response-text-container td, .ai-response-text-container th {
    background-color: #f9f9f9;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

.ai-response-text-container th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
}

.ai-response-text-container img {
    max-width: 35%;
}

.audio-chat-mentor-image.is-speaking {
    animation: zoomInOut 2s linear infinite;
}

@keyframes zoomInOut {
    0% {
        transform: scale3d(1, 1, 1);
    }
    50% {
        transform: scale3d(0.93, 0.93, 0.93);
    }
    100% {
        transform: scale3d(1, 1, 1);
    }
}

.user-prompt-input-field {
    border: .6px solid #003aad !important;
    border-radius: 10px !important;
}

.suggested-prompt-box-container-custom {
    justify-content: flex-start !important;
}

/*HIDING ALL TABLE SORT ICON */
.user-table-sort-icon {
    display: none !important;
}

.modal:not(.manage-account-modal) .modal-container .modal-header .modal-header-title {
    padding-left: unset;
}

/*
EMBED FIELDS HEIGHT INCREASED DUE TO PRIMARY/SECONDARY COLOR FIELDS*/
.textarea-4.black-bg-white-color-form-field.small-text-area {
    min-height: 70px !important;
}

.textarea-4.black-bg-white-color-form-field.small-text-area.mentor-default-prompt1-textarea.min-height-augmented {
    min-height: 80px !important;
}

.textarea-4.black-bg-white-color-form-field.min-height-reduced {
    min-height: 100px !important;
}

.dark-mode .w-input[disabled]:not(.w-input-disabled) {
    background-color: inherit;
}

.prompt-grid-element.add-prompt-grid-element {
    min-height: 210px;
}

.modal-header.padding-zero {
    padding: 0 !important;
}

.body:has(.site-top-header[style*='display: none']) .main-content {
    height: calc(100% - 80px) !important;
}


#user-prompt {
    resize: none;
    line-height: 25px;
    padding: 12px 18px;
    border: none;
}

.share-content-body{
    margin-left: 30px !important;
    margin-top: 10px !important;
}

@media screen and (max-width: 480px) {
    .body:has(.site-top-header[style*='display: none']) .main-content {
        height: calc(100% - 60px) !important;
    }

    .share-content-body{
        margin-left: 10px !important;
    }
}

.prompt-gallery-element-view-modal-header-title {
    padding-right: 35px;
}

.picker-dialog,
.picker-dialog-bg {
    z-index: 999999 !important;
}

.datasource-element-picker {
    position: relative;
    display: flex;
}

.tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the text */
    left: 50%;
    margin-left: -60px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.datasource-element-picker:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.logo-container-anonymous {
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    justify-content: center;
    align-items: center;
    height: 100%;
    display: flex;
}

.history-element-label p {
    font-size: 12px;
    line-height: 20px;
}

.modal.manage-account-modal .form-input-label.required {
    margin-left: unset !important;
}

body.dark-mode .ai-response-text-container pre, body.dark-mode .ai-response-text-container th, body.dark-mode .ai-response-text-container td {
    background-color: #222327;
}

body.dark-mode .tooltip {
    background-color: #2C2F36 !important;
    border-color: #2C2F36 !important;
}

body.dark-mode .prompt-tooltip-left-triangle-pointer {
    display: none;
}

.site-left-sidebar-block.is-uncollapsed .left-sidebar-bottom-block {
    width: auto;
    padding: 0px 13px;
    align-items: start;
}

.site-left-sidebar-block.is-uncollapsed .left-sidebar-bottom-btn {
    border-radius: 9px;
    width: 100%;
    padding: 0px 13px;
}

.site-left-sidebar-block.is-uncollapsed .left-sidebar-bottom-btn .left-sidebar-bottom-btn-label {
    display: block;
}

.site-left-sidebar-block.is-uncollapsed .left-sidebar-bottom-btn .tooltip {
    display: none !important;
}

.site-left-sidebar-block.is-uncollapsed .left-sidebar-bottom-btn {
    justify-content: start;
}

.site-left-sidebar-block .left-sidebar-bottom-btn {
    opacity: 1 !important;
}

.site-left-sidebar-block .left-sidebar-bottom-btn > img {
    opacity: .75 !important;
}

.app-mobile-sidebar .header-help-dropdown-container.help-dropdown.bottom-side-positionned .user-menu-dropdown-block {
    right: 90px !important;
}

.app-mobile-sidebar .left-sidebar-bottom-block {
    bottom: 10px !important;
}

.app-mobile-sidebar .body-left-side-block {
    height: calc(100% - 180px) !important;
}

.site-left-sidebar-block {
    overflow: hidden !important;
    z-index: 999;
}

body:has(.ibl-trial-top-header:not([style*="display: none"])) .left-sidebar-bottom-block {
    bottom: 40px !important;
}


/* The mini-switch - the box around the mini-switch-slider */
.mini-switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 15px;
}

/* Hide default HTML checkbox */
.mini-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The mini-switch-slider */
.mini-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.mini-switch-slider:before {
    position: absolute;
    content: "";
    height: 9px;
    width: 9px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .mini-switch-slider {
    background-color: #2467eb;
}

input:focus + .mini-switch-slider {
    box-shadow: 0 0 1px #2467eb;
}

input:checked + .mini-switch-slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
}

/* Rounded mini-switch-sliders */
.mini-switch-slider.round {
    border-radius: 20px;
}

.mini-switch-slider.round:before {
    border-radius: 50%;
}
.default-suggestion-prompt:before {
    content: "" !important;
}
[dir=ltr] .w-dropdown-toggle {
    padding-right: unset!important;
}

.rs-picker-popup{
    z-index: 999999 !important;
}

.rs-picker-input-group.rs-input-group{
    background: inherit !important;
    border: 0px solid gray !important;
    width: 240px !important;
}

.rs-date-range-input.rs-input{
    background: inherit !important;
}

.rs-date-range-input.rs-input:focus{
    outline: none !important;
}

.mentor-chat-history-selected{
    background: #F0F0F0 !important;
}